// source: pbtypes/gen/flight_connect/device_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_flight_connect_connection_state_pb = require('../../../pbtypes/gen/flight_connect/connection_state_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_connection_state_pb);
var pbtypes_gen_flight_connect_device_client_stats_pb = require('../../../pbtypes/gen/flight_connect/device_client_stats_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_device_client_stats_pb);
var pbtypes_gen_flight_connect_device_tunnel_stats_pb = require('../../../pbtypes/gen/flight_connect/device_tunnel_stats_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_device_tunnel_stats_pb);
var pbtypes_gen_flight_connect_transport_type_pb = require('../../../pbtypes/gen/flight_connect/transport_type_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_transport_type_pb);
goog.exportSymbol('proto.flight_connect.DeviceStats', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_connect.DeviceStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_connect.DeviceStats.repeatedFields_, null);
};
goog.inherits(proto.flight_connect.DeviceStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_connect.DeviceStats.displayName = 'proto.flight_connect.DeviceStats';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_connect.DeviceStats.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_connect.DeviceStats.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_connect.DeviceStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_connect.DeviceStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_connect.DeviceStats.toObject = function(includeInstance, msg) {
  var f, obj = {
serial: jspb.Message.getFieldWithDefault(msg, 1, ""),
connectionState: jspb.Message.getFieldWithDefault(msg, 2, 0),
enabledTransportsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
selectedTransportsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
tunnelStats: (f = msg.getTunnelStats()) && pbtypes_gen_flight_connect_device_tunnel_stats_pb.DeviceTunnelStats.toObject(includeInstance, f),
clientStats: (f = msg.getClientStats()) && pbtypes_gen_flight_connect_device_client_stats_pb.DeviceClientStats.toObject(includeInstance, f),
rttMs: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_connect.DeviceStats}
 */
proto.flight_connect.DeviceStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_connect.DeviceStats;
  return proto.flight_connect.DeviceStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_connect.DeviceStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_connect.DeviceStats}
 */
proto.flight_connect.DeviceStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerial(value);
      break;
    case 2:
      var value = /** @type {!proto.flight_connect.ConnectionState.Enum} */ (reader.readEnum());
      msg.setConnectionState(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.flight_connect.TransportType.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnabledTransports(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.flight_connect.TransportType.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSelectedTransports(values[i]);
      }
      break;
    case 5:
      var value = new pbtypes_gen_flight_connect_device_tunnel_stats_pb.DeviceTunnelStats;
      reader.readMessage(value,pbtypes_gen_flight_connect_device_tunnel_stats_pb.DeviceTunnelStats.deserializeBinaryFromReader);
      msg.setTunnelStats(value);
      break;
    case 6:
      var value = new pbtypes_gen_flight_connect_device_client_stats_pb.DeviceClientStats;
      reader.readMessage(value,pbtypes_gen_flight_connect_device_client_stats_pb.DeviceClientStats.deserializeBinaryFromReader);
      msg.setClientStats(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRttMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_connect.DeviceStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_connect.DeviceStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_connect.DeviceStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_connect.DeviceStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEnabledTransportsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getSelectedTransportsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getTunnelStats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_flight_connect_device_tunnel_stats_pb.DeviceTunnelStats.serializeBinaryToWriter
    );
  }
  f = message.getClientStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_flight_connect_device_client_stats_pb.DeviceClientStats.serializeBinaryToWriter
    );
  }
  f = message.getRttMs();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string serial = 1;
 * @return {string}
 */
proto.flight_connect.DeviceStats.prototype.getSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.setSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConnectionState.Enum connection_state = 2;
 * @return {!proto.flight_connect.ConnectionState.Enum}
 */
proto.flight_connect.DeviceStats.prototype.getConnectionState = function() {
  return /** @type {!proto.flight_connect.ConnectionState.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flight_connect.ConnectionState.Enum} value
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.setConnectionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated TransportType.Enum enabled_transports = 3;
 * @return {!Array<!proto.flight_connect.TransportType.Enum>}
 */
proto.flight_connect.DeviceStats.prototype.getEnabledTransportsList = function() {
  return /** @type {!Array<!proto.flight_connect.TransportType.Enum>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.flight_connect.TransportType.Enum>} value
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.setEnabledTransportsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.flight_connect.TransportType.Enum} value
 * @param {number=} opt_index
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.addEnabledTransports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.clearEnabledTransportsList = function() {
  return this.setEnabledTransportsList([]);
};


/**
 * repeated TransportType.Enum selected_transports = 4;
 * @return {!Array<!proto.flight_connect.TransportType.Enum>}
 */
proto.flight_connect.DeviceStats.prototype.getSelectedTransportsList = function() {
  return /** @type {!Array<!proto.flight_connect.TransportType.Enum>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.flight_connect.TransportType.Enum>} value
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.setSelectedTransportsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.flight_connect.TransportType.Enum} value
 * @param {number=} opt_index
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.addSelectedTransports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.clearSelectedTransportsList = function() {
  return this.setSelectedTransportsList([]);
};


/**
 * optional DeviceTunnelStats tunnel_stats = 5;
 * @return {?proto.flight_connect.DeviceTunnelStats}
 */
proto.flight_connect.DeviceStats.prototype.getTunnelStats = function() {
  return /** @type{?proto.flight_connect.DeviceTunnelStats} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flight_connect_device_tunnel_stats_pb.DeviceTunnelStats, 5));
};


/**
 * @param {?proto.flight_connect.DeviceTunnelStats|undefined} value
 * @return {!proto.flight_connect.DeviceStats} returns this
*/
proto.flight_connect.DeviceStats.prototype.setTunnelStats = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.clearTunnelStats = function() {
  return this.setTunnelStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_connect.DeviceStats.prototype.hasTunnelStats = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DeviceClientStats client_stats = 6;
 * @return {?proto.flight_connect.DeviceClientStats}
 */
proto.flight_connect.DeviceStats.prototype.getClientStats = function() {
  return /** @type{?proto.flight_connect.DeviceClientStats} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flight_connect_device_client_stats_pb.DeviceClientStats, 6));
};


/**
 * @param {?proto.flight_connect.DeviceClientStats|undefined} value
 * @return {!proto.flight_connect.DeviceStats} returns this
*/
proto.flight_connect.DeviceStats.prototype.setClientStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.clearClientStats = function() {
  return this.setClientStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_connect.DeviceStats.prototype.hasClientStats = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 rtt_ms = 7;
 * @return {number}
 */
proto.flight_connect.DeviceStats.prototype.getRttMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceStats} returns this
 */
proto.flight_connect.DeviceStats.prototype.setRttMs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


goog.object.extend(exports, proto.flight_connect);

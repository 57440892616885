// source: pbtypes/gen/flight_connect/device_tunnel_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb = require('../../../pbtypes/gen/flight_connect/device_tunnel_channel_stats_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb);
goog.exportSymbol('proto.flight_connect.DeviceTunnelStats', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_connect.DeviceTunnelStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_connect.DeviceTunnelStats.repeatedFields_, null);
};
goog.inherits(proto.flight_connect.DeviceTunnelStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_connect.DeviceTunnelStats.displayName = 'proto.flight_connect.DeviceTunnelStats';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_connect.DeviceTunnelStats.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_connect.DeviceTunnelStats.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_connect.DeviceTunnelStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_connect.DeviceTunnelStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_connect.DeviceTunnelStats.toObject = function(includeInstance, msg) {
  var f, obj = {
rxChunkCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
txChunkCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
rxByteCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
txByteCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
avgTxTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
maxTxTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
minTxTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
totalTxTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
rxDroppedEvents: jspb.Message.getFieldWithDefault(msg, 9, 0),
rxDroppedChunks: jspb.Message.getFieldWithDefault(msg, 10, 0),
rxChannelStatsList: jspb.Message.toObjectList(msg.getRxChannelStatsList(),
    pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.toObject, includeInstance),
txChannelStatsList: jspb.Message.toObjectList(msg.getTxChannelStatsList(),
    pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_connect.DeviceTunnelStats}
 */
proto.flight_connect.DeviceTunnelStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_connect.DeviceTunnelStats;
  return proto.flight_connect.DeviceTunnelStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_connect.DeviceTunnelStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_connect.DeviceTunnelStats}
 */
proto.flight_connect.DeviceTunnelStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxChunkCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTxChunkCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxByteCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTxByteCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgTxTimeMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxTxTimeMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinTxTimeMs(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTxTimeMs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxDroppedEvents(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxDroppedChunks(value);
      break;
    case 11:
      var value = new pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats;
      reader.readMessage(value,pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.deserializeBinaryFromReader);
      msg.addRxChannelStats(value);
      break;
    case 12:
      var value = new pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats;
      reader.readMessage(value,pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.deserializeBinaryFromReader);
      msg.addTxChannelStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_connect.DeviceTunnelStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_connect.DeviceTunnelStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_connect.DeviceTunnelStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_connect.DeviceTunnelStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRxChunkCount();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTxChunkCount();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getRxByteCount();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getTxByteCount();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getAvgTxTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxTxTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMinTxTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalTxTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getRxDroppedEvents();
  if (f !== 0) {
    writer.writeSint32(
      9,
      f
    );
  }
  f = message.getRxDroppedChunks();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getRxChannelStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.serializeBinaryToWriter
    );
  }
  f = message.getTxChannelStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 rx_chunk_count = 1;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getRxChunkCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setRxChunkCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 tx_chunk_count = 2;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getTxChunkCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setTxChunkCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 rx_byte_count = 3;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getRxByteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setRxByteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional sint32 tx_byte_count = 4;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getTxByteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setTxByteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double avg_tx_time_ms = 5;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getAvgTxTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setAvgTxTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double max_tx_time_ms = 6;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getMaxTxTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setMaxTxTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double min_tx_time_ms = 7;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getMinTxTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setMinTxTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_tx_time_ms = 8;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getTotalTxTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setTotalTxTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional sint32 rx_dropped_events = 9;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getRxDroppedEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setRxDroppedEvents = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional sint32 rx_dropped_chunks = 10;
 * @return {number}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getRxDroppedChunks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.setRxDroppedChunks = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated DeviceTunnelChannelStats rx_channel_stats = 11;
 * @return {!Array<!proto.flight_connect.DeviceTunnelChannelStats>}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getRxChannelStatsList = function() {
  return /** @type{!Array<!proto.flight_connect.DeviceTunnelChannelStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats, 11));
};


/**
 * @param {!Array<!proto.flight_connect.DeviceTunnelChannelStats>} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
*/
proto.flight_connect.DeviceTunnelStats.prototype.setRxChannelStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.flight_connect.DeviceTunnelChannelStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flight_connect.DeviceTunnelChannelStats}
 */
proto.flight_connect.DeviceTunnelStats.prototype.addRxChannelStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.flight_connect.DeviceTunnelChannelStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.clearRxChannelStatsList = function() {
  return this.setRxChannelStatsList([]);
};


/**
 * repeated DeviceTunnelChannelStats tx_channel_stats = 12;
 * @return {!Array<!proto.flight_connect.DeviceTunnelChannelStats>}
 */
proto.flight_connect.DeviceTunnelStats.prototype.getTxChannelStatsList = function() {
  return /** @type{!Array<!proto.flight_connect.DeviceTunnelChannelStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_flight_connect_device_tunnel_channel_stats_pb.DeviceTunnelChannelStats, 12));
};


/**
 * @param {!Array<!proto.flight_connect.DeviceTunnelChannelStats>} value
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
*/
proto.flight_connect.DeviceTunnelStats.prototype.setTxChannelStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.flight_connect.DeviceTunnelChannelStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flight_connect.DeviceTunnelChannelStats}
 */
proto.flight_connect.DeviceTunnelStats.prototype.addTxChannelStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.flight_connect.DeviceTunnelChannelStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_connect.DeviceTunnelStats} returns this
 */
proto.flight_connect.DeviceTunnelStats.prototype.clearTxChannelStatsList = function() {
  return this.setTxChannelStatsList([]);
};


goog.object.extend(exports, proto.flight_connect);
